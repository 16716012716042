// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .contacts h1 {
    font-size: 30px;
    font-weight: 400;
    color: #C5C6C7;
    padding: 0;
    margin: 0;
  }
  .contacts p {
    color: #C5C6C7;
    padding: 0;
    margin: 0;
  }
  .contacts .items {
    margin: 30px 0;
  }
  .contacts .box p {
    font-size: 17px;
    margin-top: 10px;
    padding: 0;
    margin: 0;
  }
  .contacts h4{
    padding: 0;
    margin: 0;
  }
  .contacts textarea,
  .contacts input {
    width: 90%;
    padding: 10px;
    margin-bottom: 15px;
    outline: none;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #000;
    background-color: #C5C6C7;
  }
  .contacts h3 {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #C5C6C7;
  }
  .contacts span {
    color: #1eb2a6;
    font-weight: 600;
    font-size: 15px;
    word-spacing: 5px;
  }
  .contacts .container{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
 
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/contact/contact.css"],"names":[],"mappings":";EACE;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,UAAU;IACV,SAAS;EACX;EACA;IACE,cAAc;IACd,UAAU;IACV,SAAS;EACX;EACA;IACE,cAAc;EAChB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;EACA;IACE,UAAU;IACV,SAAS;EACX;EACA;;IAEE,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,sBAAsB;IACtB,yBAAyB;EAC3B;EACA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;EAChB;EACA;IACE,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;EACA;;EAEA","sourcesContent":["\n  .contacts h1 {\n    font-size: 30px;\n    font-weight: 400;\n    color: #C5C6C7;\n    padding: 0;\n    margin: 0;\n  }\n  .contacts p {\n    color: #C5C6C7;\n    padding: 0;\n    margin: 0;\n  }\n  .contacts .items {\n    margin: 30px 0;\n  }\n  .contacts .box p {\n    font-size: 17px;\n    margin-top: 10px;\n    padding: 0;\n    margin: 0;\n  }\n  .contacts h4{\n    padding: 0;\n    margin: 0;\n  }\n  .contacts textarea,\n  .contacts input {\n    width: 90%;\n    padding: 10px;\n    margin-bottom: 15px;\n    outline: none;\n    border-radius: 10px;\n    overflow: hidden;\n    border: 1px solid #000;\n    background-color: #C5C6C7;\n  }\n  .contacts h3 {\n    margin-top: 20px;\n    font-weight: 500;\n    margin-bottom: 10px;\n    color: #C5C6C7;\n  }\n  .contacts span {\n    color: #1eb2a6;\n    font-weight: 600;\n    font-size: 15px;\n    word-spacing: 5px;\n  }\n  .contacts .container{\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n  }\n  @media screen and (max-width: 768px) {\n \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
